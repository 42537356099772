import { Navigate } from "react-router-dom";
import {
  userSignal,
  customerSignal,
  refreshCustomerSignal,
} from "../signals/userSignals";
import { useSignalEffect } from "@preact/signals-react";

const RedirectPage = () => {
  useSignalEffect(() => {
    if (userSignal.value && "access_token" in userSignal.value) {
      refreshCustomerSignal();
    }
  });

  if (
    !(
      customerSignal.value &&
      "credentials" in customerSignal.value &&
      customerSignal.value.credentials.length > 0
    )
  ) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <Navigate to="/onboarding" />
    </>
  );
};

export default RedirectPage;
