import CustomerProfile from "../components/CustomerProfile";
import LogoutButton from "../components/LogoutButton";
import logo from "../img/logo.png";

const HeadingTile = ({ expanded }) => {
  return (
    <>
      <div className="columns">
        <div className="column">
          <span>{new Date().toDateString()}</span>
        </div>
        <div className="column">
          <img src={logo} alt="MI6 Analytics" style={{ maxHeight: "70px" }} />
        </div>
        <div className="column">
          <CustomerProfile />
        </div>
      </div>
      {expanded && (
        <div className="mt-5">
          <LogoutButton />
        </div>
      )}
    </>
  );
};

export default HeadingTile;
