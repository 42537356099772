import { useSignal } from "@preact/signals-react";
import {
  userSignal,
  propertySignal,
  conversionGroupSignal,
  refreshPropertySignal,
} from "../signals/userSignals";
import api from "../utils/api";

const ConversionGroupConfiguration = () => {
  const conversionGroupName = useSignal("");

  const addConversionGroup = (item) => {
    api(userSignal.value.access_token)
      .post("settings/conversion_group/add", item)
      .then((response) => {
        refreshPropertySignal();
        conversionGroupSignal.value = response.data;
      });
  };

  if (propertySignal.value === null) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <div>
        {propertySignal.value["conversion_groups"].length > 0 && (
          <b>Key events</b>
        )}

        {propertySignal.value["conversion_groups"].map((group) => (
          <div key={group.id}>{group.name}</div>
        ))}
      </div>
      <div className="mt-5">
        <b>Add key event</b>
        <br />
        <div className="field has-addons">
          <div className="control">
            <input
              className="input"
              type="text"
              onChange={(e) => (conversionGroupName.value = e.target.value)}
              id="conversionGroupName"
            />
          </div>
          <div className="control">
            <button
              className="button is-info"
              onClick={() =>
                addConversionGroup({
                  name: conversionGroupName.value,
                  google_property_id: propertySignal.value.id,
                })
              }
            >
              <span className="icon">
                <i className="fas fa-plus"></i>
              </span>
              <span>Add</span>
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default ConversionGroupConfiguration;
