import { customerSignal } from "../signals/userSignals";
import Heading from "./Heading";

const CustomerProfile = () => {
  if (customerSignal.value === null) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <Heading
        title={customerSignal.value.name}
        subtitle={customerSignal.value.email}
      />
    </>
  );
};

export default CustomerProfile;
