import { Bar } from "react-chartjs-2";
import colors from "../utils/color";
import {
  Chart,
  BarElement,
  LinearScale,
  Title,
  CategoryScale,
  Filler,
} from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { reduceRecords, orderRecords } from "../utils/data";
import { conversionGroupSignal } from "../signals/userSignals";

Chart.register(
  BarElement,
  LinearScale,
  Title,
  CategoryScale,
  Filler,
  ChartDataLabels
);

const SessionsVsConversionsByChannelBarChart = ({ records }) => {
  const prepareData = (data) => {
    const reduceConfig = {
      groupBy: ["firstUserDefaultChannelGroup"],
      metrics: [
        { metric: "sessions", agg: "sum" },
        { metric: "cg-" + conversionGroupSignal.value["id"], agg: "sum" },
      ],
    };
    return orderRecords(reduceRecords(data, reduceConfig), "sessions", "desc");
  };

  const reducedRecords = prepareData(records);

  const labels = reducedRecords.map(
    (record) => record["firstUserDefaultChannelGroup"]
  );
  const datasets = [
    {
      label: "Visits",
      data: reducedRecords.map((record) => record["sessions"]),
      backgroundColor: colors.array[0].backgroundColor,
      borderColor: colors.array[0].borderColor,
      borderWidth: 1,
      yAxisID: "y-axis-1",
      pointRadius: 0,
    },
    {
      label: conversionGroupSignal.value["name"],
      data: reducedRecords.map(
        (record) => record["cg-" + conversionGroupSignal.value["id"]]
      ),
      backgroundColor: colors.array[1].backgroundColor,
      borderColor: colors.array[1].borderColor,
      borderWidth: 1,
      yAxisID: "y-axis-2",
      pointRadius: 0,
    },
  ];

  const data = {
    labels: labels,
    datasets: datasets,
  };

  const options = {
    scales: {
      "y-axis-1": {
        // use the id directly
        type: "linear",
        display: true,
        position: "left",
        beginAtZero: true,
      },
      "y-axis-2": {
        // use the id directly
        type: "linear",
        display: true,
        position: "right",
        grid: {
          drawOnChartArea: false, // ensures the second y-axis doesn't draw grid lines
        },
        beginAtZero: true,
      },
    },
    plugins: {
      legend: {
        position: "bottom",
      },
      datalabels: {
        display: true,
      },
    },
  };

  return (
    <div className="chart-container">
      <Bar data={data} plugins={[ChartDataLabels]} options={options} />
    </div>
  );
};

export default SessionsVsConversionsByChannelBarChart;
