const ExpandingSection = ({ children, expanded }) => {
  const toggleExpanded = () => {
    expanded.value = !expanded.value;
  };

  return (
    <div className="box">
      <div className="columns">
        <div className="column is-1">
          <button className="button is-small is-info" onClick={toggleExpanded}>
            <span className="icon">
              <i
                className={expanded.value ? "fas fa-minus" : "fas fa-plus"}
              ></i>
            </span>
          </button>
        </div>
        <div className="column is-11">{children}</div>
      </div>
    </div>
  );
};

export default ExpandingSection;
