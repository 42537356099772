import GoogleUserLogin from "../components/GoogleUserLogin";
import { Navigate } from "react-router-dom";

import {
  userSignal,
  refreshCustomerSignal,
  customerSignal,
} from "../signals/userSignals";

const SignInPage = () => {
  if (userSignal.value) {
    refreshCustomerSignal();
  }

  if (customerSignal.value) {
    return <Navigate to="/onboarding" />;
  }

  return (
    <div className="container is-centered">
      <div>
        <GoogleUserLogin />
      </div>
    </div>
  );
};

export default SignInPage;
