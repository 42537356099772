import { useRouteError } from "react-router-dom";

const ErrorPage = ({ errorMessage = "" }) => {
  const routeError = useRouteError();

  return (
    <div>
      <h2 className="subtitle has-text-centered">Er is iets fout gegaan</h2>
      <div className="notification has-text-centered is-danger is-light">
        <b>Foutmelding</b>
        <br />
        {routeError
          ? routeError.statusText || routeError.message
          : errorMessage}
      </div>
    </div>
  );
};

export default ErrorPage;
