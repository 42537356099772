import SessionsVsConversionsByChannelLineChart from "../visuals/SessionsVsConversionsByChannelLineChart";
import { reduceRecords, orderRecords } from "../utils/data";
import Explanation from "../components/Explanation";
import Heading from "../components/Heading";
import { conversionGroupSignal } from "../signals/userSignals";

const ChannelSplitTile = ({ records, expanded }) => {
  const prepareData = (data) => {
    const reduceConfig = {
      groupBy: ["firstUserDefaultChannelGroup"],
      metrics: [{ metric: "sessions", agg: "sum" }],
    };
    return orderRecords(reduceRecords(data, reduceConfig), "sessions", "desc");
  };

  const reducedRecords = prepareData(records);

  const columns = prepareData(reducedRecords).map((record, index) => (
    <div className="column is-one-third has-text-centered">
      <b>{record["firstUserDefaultChannelGroup"]}</b>
      <SessionsVsConversionsByChannelLineChart
        records={records}
        channel={record["firstUserDefaultChannelGroup"]}
      />
      {expanded && (
        <Explanation>
          Hier kan een uitleg tekst. Hier kan een uitleg tekst. Hier kan een
          uitleg tekst.
        </Explanation>
      )}
    </div>
  ));

  const columnGroups = [];
  for (let i = 0; i < columns.length; i += 3) {
    columnGroups.push(<div className="columns">{columns.slice(i, i + 3)}</div>);
  }

  return (
    <>
      <Heading
        title="Trend by channel"
        subtitle={"Visits vs. " + conversionGroupSignal.value.name}
      />
      {columnGroups}
    </>
  );
};

export default ChannelSplitTile;
