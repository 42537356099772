import { useSignalEffect } from "@preact/signals-react";

import {
  userSignal,
  customerSignal,
  propertySignal,
  conversionGroupSignal,
  recordsSignal,
  dateSignal,
  filteredRecordsSignal,
} from "../signals/userSignals";

const SignalManager = () => {
  useSignalEffect(() => {
    if (userSignal.value) {
      localStorage.setItem("userSignal", JSON.stringify(userSignal.value));
    }
  });

  useSignalEffect(() => {
    if (customerSignal.value) {
      localStorage.setItem(
        "customerSignal",
        JSON.stringify(customerSignal.value)
      );
    }
  });

  useSignalEffect(() => {
    if (propertySignal.value) {
      localStorage.setItem(
        "propertySignal",
        JSON.stringify(propertySignal.value)
      );
    }
  });

  useSignalEffect(() => {
    if (conversionGroupSignal.value) {
      localStorage.setItem(
        "conversionGroupSignal",
        JSON.stringify(conversionGroupSignal.value)
      );
    }
  });

  useSignalEffect(() => {
    if (dateSignal.value) {
      localStorage.setItem("dateSignal", JSON.stringify(dateSignal.value));
    }
  });

  useSignalEffect(() => {
    if (recordsSignal.value) {
      localStorage.setItem(
        "recordsSignal",
        JSON.stringify(recordsSignal.value)
      );
    }
  });

  useSignalEffect(() => {
    if (filteredRecordsSignal.value) {
      localStorage.setItem(
        "filteredRecordsSignal",
        JSON.stringify(filteredRecordsSignal.value)
      );
    }
  });

  useSignalEffect(() => {
    if (userSignal.value === null && localStorage.getItem("userSignal")) {
      userSignal.value = JSON.parse(localStorage.getItem("userSignal"));
    }
  });

  {
    /*

  useSignalEffect(() => {
    if (
      customerSignal.value === null &&
      localStorage.getItem("customerSignal")
    ) {
      customerSignal.value = JSON.parse(localStorage.getItem("customerSignal"));
    }
  });

  useSignalEffect(() => {
    if (
      propertySignal.value === null &&
      localStorage.getItem("propertySignal")
    ) {
      propertySignal.value = JSON.parse(localStorage.getItem("propertySignal"));
    }
  });

  useSignalEffect(() => {
    if (
      conversionGroupSignal.value === null &&
      localStorage.getItem("conversionGroupSignal")
    ) {
      conversionGroupSignal.value = JSON.parse(
        localStorage.getItem("conversionGroupSignal")
      );
    }
  });

  useSignalEffect(() => {
    if (recordsSignal.value === null && localStorage.getItem("recordsSignal")) {
      recordsSignal.value = JSON.parse(localStorage.getItem("recordsSignal"));
    }
  });

  useSignalEffect(() => {
    if (
      filteredRecordsSignal.value === null &&
      localStorage.getItem("filteredRecordsSignal")
    ) {
      filteredRecordsSignal.value = JSON.parse(
        localStorage.getItem("filteredRecordsSignal")
      );
    }
  });

  useSignalEffect(() => {
    if (dateSignal.value === null && localStorage.getItem("dateSignal")) {
      dateSignal.value = JSON.parse(localStorage.getItem("dateSignal"));
    }
  });
*/
  }

  useSignalEffect(() => {
    if (
      propertySignal.value &&
      propertySignal.value.conversion_groups.length > 0
    ) {
      if (conversionGroupSignal.value) {
        if (
          propertySignal.value.conversion_groups.filter(
            (conversion_group) =>
              conversion_group.id === conversionGroupSignal.value.id
          ).length > 0
        ) {
          conversionGroupSignal.value =
            propertySignal.value.conversion_groups.filter(
              (conversion_group) =>
                conversion_group.id === conversionGroupSignal.value.id
            )[0];
        } else {
          conversionGroupSignal.value =
            propertySignal.value.conversion_groups[0];
        }
      } else {
        conversionGroupSignal.value = propertySignal.value.conversion_groups[0];
      }
    }
  });

  useSignalEffect(() => {
    if (dateSignal.value && recordsSignal.value) {
      filteredRecordsSignal.value = recordsSignal.value.filter((record) => {
        return (
          record["yearMonth"] >= dateSignal.value.startDate &&
          record["yearMonth"] <= dateSignal.value.endDate
        );
      });
    }
  });

  return <></>;
};

export default SignalManager;
