import { createBrowserRouter, RouterProvider } from "react-router-dom";
import "bulma/css/bulma.min.css";
import "./css/other.css";
import { Navigate } from "react-router-dom";
import { GoogleOAuthProvider } from "@react-oauth/google";

import SignalManager from "./general/SignalManager";
import DashboardPage from "./pages/DashboardPage";
import ErrorPage from "./pages/ErrorPage";
import RedirectPage from "./pages/RedirectPage";
import SignInPage from "./pages/SignInPage";
import OnboardingPage from "./pages/OnboardingPage";

const App = () => {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <Navigate to="/signin" />,
      errorElement: <ErrorPage />,
    },
    { path: "/", element: <Navigate to="/signin" /> },
    { path: "/redirect", element: <RedirectPage /> },
    { path: "/signin", element: <SignInPage /> },
    { path: "/onboarding", element: <OnboardingPage /> },
    { path: "/dashboard", element: <DashboardPage /> },
  ]);

  // HTML-output
  return (
    <GoogleOAuthProvider clientId="724747242946-vaukhigihvr9di7c9ra71skda15jb5hl.apps.googleusercontent.com">
      <SignalManager />
      <RouterProvider router={router} />
    </GoogleOAuthProvider>
  );
};

export default App;
