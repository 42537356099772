import { conversionGroupSignal } from "../signals/userSignals";

import SessionsByDevicePieChart from "../visuals/SessionsByDevicePieChart";
import ConversionsByDevicePieChart from "../visuals/ConversionsByDevicePieChart";
import DifferenceByDeviceGaugeChart from "../visuals/DifferenceByDeviceGaugeChart";
import SessionsByPaidPieChart from "../visuals/SessionsByPaidPieChart";
import ConversionsByPaidPieChart from "../visuals/ConversionsByPaidPieChart";
import DifferenceByPaidGaugeChart from "../visuals/DifferenceByPaidGaugeChart";

const DeviceAndPaidTile = ({ records, expanded }) => {
  return (
    <>
      <div className="columns">
        <div className="column is-6 has-text-centered is-size-5 has-text-weight-bold">
          Device categories
        </div>
        <div className="column is-6 has-text-centered is-size-5 has-text-weight-bold">
          Paid vs. organic
        </div>
      </div>
      <div className="columns">
        <div className="column is-2">
          <div className="has-text-centered">
            Visits
            <br />
            <br />
            <SessionsByDevicePieChart records={records} />
          </div>
        </div>
        <div className="column is-2">
          <div className="has-text-centered">
            {conversionGroupSignal.value["name"]}
            <br />
            <br />
            <ConversionsByDevicePieChart records={records} />
          </div>
        </div>
        <div className="column is-2">
          <div className="has-text-centered">
            Difference
            <br />
            <br />
            <DifferenceByDeviceGaugeChart records={records} />
          </div>
        </div>
        <div className="column is-2">
          <div className="has-text-centered">
            Visits
            <br />
            <br />
            <SessionsByPaidPieChart records={records} />
          </div>
        </div>
        <div className="column is-2">
          <div className="has-text-centered">
            {conversionGroupSignal.value["name"]}
            <br />
            <br />
            <ConversionsByPaidPieChart records={records} />
          </div>
        </div>
        <div className="column is-2">
          <div className="has-text-centered">
            Difference
            <br />
            <br />
            <DifferenceByPaidGaugeChart records={records} />
          </div>
        </div>
      </div>
    </>
  );
};

export default DeviceAndPaidTile;
