const Explanation = ({ children }) => {
  return (
    <div className="notification mt-3 mb-5">
      <div className="media">
        <div className="media-left">
          <span className="icon">
            <i className="fa-solid fa-magnifying-glass"></i>
          </span>
        </div>
        <div className="media-content">{children}</div>
      </div>
    </div>
  );
};

export default Explanation;
