import { linearRegression } from "simple-statistics";

const reduceRecords = (data, reduceConfig) => {
  // Helper functie om de key te genereren voor het groeperen
  const createGroupKey = (record) =>
    reduceConfig.groupBy.map((field) => record[field]).join("::");

  // Tijdelijke opslag voor de geaggregeerde gegevens
  const aggregates = {};

  // Loop over elk record in de data
  data.forEach((record) => {
    // Maak de groepssleutel aan
    const key = createGroupKey(record);

    // Initialiseer de groep indien nodig
    if (!aggregates[key]) {
      aggregates[key] = { count: 0 };
      reduceConfig.groupBy.forEach(
        (group) => (aggregates[key][group] = record[group])
      );
      reduceConfig.metrics.forEach((metric) => {
        aggregates[key][metric.metric] =
          metric.agg === "sum" ? 0 : { sum: 0, count: 0 };
      });
    }

    // Voeg de metric waarden toe
    reduceConfig.metrics.forEach((metric) => {
      const value = parseFloat(record[metric.metric]) || 0;
      if (metric.agg === "sum") {
        aggregates[key][metric.metric] += value;
      } else if (metric.agg === "avg") {
        aggregates[key][metric.metric].sum += value;
        aggregates[key][metric.metric].count++;
      }
    });

    // Verhoog het aantal records in de groep
    aggregates[key].count++;
  });

  // Bereken de gemiddelden en maak de uiteindelijke lijst van geaggregeerde gegevens
  return Object.values(aggregates).map((group) => {
    reduceConfig.metrics.forEach((metric) => {
      if (metric.agg === "avg" && group[metric.metric].count > 0) {
        group[metric.metric] =
          group[metric.metric].sum / group[metric.metric].count;
      }
    });
    delete group.count; // Verwijder de interne telling
    return group;
  });
};

const applyFormat = (value, format) => {
  if (value === null) {
    return null;
  } else if (format === "number") {
    return parseInt(value).toLocaleString("nl-NL");
  } else if (format === "decimal") {
    return parseFloat(value).toFixed(2).toLocaleString("nl-NL");
  } else if (format === "percentage") {
    return (parseFloat(value) * 100).toFixed(2) + " %";
  } else if (format === "duration") {
    return (
      new Date(parseInt(value) * 1000)
        .toISOString()
        .substr(11, 4)
        .replace(/^[0:]*/, "") +
      new Date(parseInt(value) * 1000).toISOString().substr(15, 4)
    );
  }

  return value;
};

const isNumeric = (value) => {
  return /^[0-9,.]*$/.test(value);
};

const orderRecords = (records, orderBy, order = "desc") => {
  const recordsCopy = [...records];
  return recordsCopy.sort((a, b) => {
    if (isNumeric(a[orderBy]) && isNumeric(b[orderBy])) {
      if (order === "asc") {
        return parseFloat(a[orderBy]) - parseFloat(b[orderBy]);
      } else {
        return parseFloat(b[orderBy]) - parseFloat(a[orderBy]);
      }
    }

    if (order === "asc") {
      return a[orderBy] > b[orderBy] ? 1 : -1;
    } else {
      return a[orderBy] < b[orderBy] ? 1 : -1;
    }
  });
};

const addTrend = (data, trendConfig) => {
  trendConfig.metrics.forEach((metric) => {
    data = addTrendToMetric(data, metric.metric);
  });

  return data;
};

const addTrendToMetric = (data, metric) => {
  const formattedData = data.map((record, index) => [
    index,
    parseFloat(record[metric]),
  ]);

  const regression = linearRegression(formattedData);

  const trendKey = `${metric}Trend`;
  const dataWithTrend = data.map((record, index) => ({
    ...record,
    [trendKey]: regression.m * index + regression.b,
  }));

  return dataWithTrend;
};

export { reduceRecords, applyFormat, orderRecords, addTrend };
