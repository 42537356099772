import { useSignal } from "@preact/signals-react";
import { Navigate } from "react-router-dom";

import {
  userSignal,
  customerSignal,
  propertySignal,
  conversionGroupSignal,
  recordsSignal,
  refreshCustomerSignal,
} from "../signals/userSignals";

import OnboardingBlock from "../components/OnboardingBlock";
import GoogleUserLogin from "../components/GoogleUserLogin";
import GoogleApiLogin from "../components/GoogleApiLogin";
import GooglePropertyConfiguration from "../components/GooglePropertyConfiguration";
import ConversionGroupConfiguration from "../components/ConversionGroupConfiguration";
import ConversionConfiguration from "../components/ConversionConfiguration";
import api from "../utils/api";
import Heading from "../components/Heading";
import Loading from "../components/Loading";

const OnboardingPage = () => {
  const googlePropertyConfigurationCompleted = useSignal(false);
  const conversionGroupConfigurationCompleted = useSignal(false);
  const conversionConfigurationCompleted = useSignal(false);

  const reloadRecords = async (google_property_id) => {
    api(userSignal.value.access_token)
      .get("/data_extract/reload_queries/" + google_property_id)
      .then((response) => {
        getRecords(google_property_id);
      });
  };

  const getRecords = async (google_property_id) => {
    api(userSignal.value.access_token)
      .get("/data_load/main_data/" + google_property_id)
      .then((response) => {
        recordsSignal.value = response.data;
      });
  };

  if (
    userSignal.value &&
    customerSignal.value &&
    propertySignal.value &&
    conversionGroupSignal.value &&
    recordsSignal.value
  ) {
    return <Navigate to="/dashboard" />;
  }

  if (
    userSignal.value &&
    customerSignal.value &&
    propertySignal.value &&
    conversionGroupSignal.value &&
    "conversions" in conversionGroupSignal.value &&
    conversionGroupSignal.value.conversions.length > 0 &&
    conversionConfigurationCompleted.value
  ) {
    reloadRecords(propertySignal.value.id);
    return <Loading />;
  }

  if (
    userSignal.value &&
    customerSignal.value &&
    propertySignal.value &&
    conversionGroupSignal.value &&
    conversionGroupConfigurationCompleted.value
  ) {
    return (
      <OnboardingBlock>
        <Heading
          title="Configure pages"
          subtitle="Select the pages you want to track as part of the selected key event"
        />
        <ConversionConfiguration />
        <button
          className="button is-info"
          onClick={() => (conversionConfigurationCompleted.value = true)}
        >
          Next step
        </button>
      </OnboardingBlock>
    );
  }

  if (
    userSignal.value &&
    customerSignal.value &&
    propertySignal.value &&
    googlePropertyConfigurationCompleted.value
  ) {
    return (
      <OnboardingBlock>
        <Heading title="Key events" subtitle="Create a key event" />
        <p>
          In this step we're going to create a key event. This could be the
          subscription to a newsletter, the ordering of a product, or any other
          event that is important to your business. A key event is tracked by
          identifying a group of pages that signify that this event has
          happened. In this step, we first choose an event that we want to track
          like: 'Booking', 'Newsletter subscription' or 'Contact form
          submission'.
        </p>
        <p>
          Please choose a event name that is descriptive and easy to understand.
          (Don't worry, you can change all events at a later stage.)
        </p>
        <ConversionGroupConfiguration />
        <button
          className="button is-info"
          onClick={() => (conversionGroupConfigurationCompleted.value = true)}
        >
          Next step
        </button>
      </OnboardingBlock>
    );
  }

  if (
    userSignal.value &&
    customerSignal.value &&
    "credentials" in customerSignal.value &&
    customerSignal.value.credentials.length > 0 &&
    customerSignal.value.credentials[0].access_token
  ) {
    return (
      <OnboardingBlock>
        <Heading
          title="Configure Google Property"
          subtitle="Select the Google Property you want to track"
        />
        <GooglePropertyConfiguration />
        <button
          className="button is-info"
          onClick={() => (googlePropertyConfigurationCompleted.value = true)}
        >
          Next step
        </button>
      </OnboardingBlock>
    );
  }

  if (userSignal.value && customerSignal.value) {
    return (
      <OnboardingBlock>
        <Heading
          title="Sign in with Google"
          subtitle="Sign in with your Google Analytics account to get started"
        />
        <GoogleApiLogin />
      </OnboardingBlock>
    );
  }

  if (userSignal.value && userSignal.value.access_token) {
    refreshCustomerSignal();
  }

  return (
    <OnboardingBlock>
      <Heading
        title="Sign in with Google"
        subtitle="Sign in with your Google account to get started"
      />
      <GoogleUserLogin />
    </OnboardingBlock>
  );
};

export default OnboardingPage;
