import { conversionGroupSignal } from "../signals/userSignals";

import Heading from "../components/Heading";
import SessionsVsConversionsLineChart from "../visuals/SessionsVsConversionsLineChart";

const TrendTile = ({ records, expanded }) => {
  return (
    <div>
      <Heading
        title="Trend"
        subtitle={"Visits vs. " + conversionGroupSignal.value["name"]}
      />
      <SessionsVsConversionsLineChart records={records} />
    </div>
  );
};

export default TrendTile;
