import { reduceRecords, applyFormat } from "../utils/data";
import { conversionGroupSignal } from "../signals/userSignals";

const KpiCards = ({ records }) => {
  const prepareData = (data) => {
    const reduceConfig = {
      groupBy: [],
      metrics: [
        { metric: "sessions", agg: "sum" },
        { metric: "cg-" + conversionGroupSignal.value["id"], agg: "sum" },
      ],
    };
    return reduceRecords(data, reduceConfig);
  };

  return (
    <div className="columns">
      <div className="column has-text-centered">
        <p>Visits</p>
        <p className="title">
          {applyFormat(prepareData(records)[0]["sessions"], "number")}
        </p>
      </div>
      <div className="column has-text-centered">
        <p>{conversionGroupSignal.value.name}</p>
        <p className="title">
          {applyFormat(
            prepareData(records)[0]["cg-" + conversionGroupSignal.value["id"]],
            "number"
          )}
        </p>
      </div>
      <div className="column has-text-centered">
        <p>Ratio</p>
        <p className="title">
          {Math.round(
            (100 *
              100 *
              prepareData(records)[0][
                "cg-" + conversionGroupSignal.value["id"]
              ]) /
              prepareData(records)[0]["sessions"]
          ) / 100}{" "}
          %
        </p>
      </div>
    </div>
  );
};

export default KpiCards;
