import { useSignal, useSignalEffect } from "@preact/signals-react";

import { reduceRecords, orderRecords, applyFormat } from "../utils/data";

const BounceRateToplistByPage = ({ records }) => {
  const offset = useSignal(0);
  const pageSize = useSignal(10);
  const reducedRecords = useSignal(null);
  const recordCount = useSignal(null);

  const increaseOffset = () => {
    if (offset.value + pageSize.value < recordCount.value)
      offset.value += pageSize.value;
  };

  const decreaseOffset = () => {
    if (offset.value - pageSize.value >= 0) offset.value -= pageSize.value;
  };

  const prepareData = (data, offset) => {
    const reduceConfig = {
      groupBy: ["hostName", "pagePath"],
      metrics: [
        { metric: "bounceRate", agg: "avg" },
        { metric: "sessions", agg: "sum" },
      ],
    };

    const filteredRecords = data.filter(
      (record) =>
        record["sessions"] > 100 &&
        record["pagePath"] !== "(not set)" &&
        record["pagePath"] !== "/"
    );

    recordCount.value = filteredRecords.length;

    const orderedRecords = orderRecords(
      reduceRecords(filteredRecords, reduceConfig),
      "bounceRate",
      "desc"
    );

    const sliceEnd =
      offset + pageSize > orderedRecords.length
        ? orderedRecords.length
        : offset + pageSize;
    const slicedRecords = orderedRecords.slice(offset, sliceEnd);

    return slicedRecords;
  };

  useSignalEffect(() => {
    reducedRecords.value = prepareData(records, offset.value);
  });

  if (reducedRecords.value === null) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <table className="table is-fullwidth">
        <thead>
          <tr>
            <th>Page</th>
            <th>Bounce Rate</th>
            <th>Visits</th>
          </tr>
        </thead>
        <tbody>
          {reducedRecords.value.map((record) => (
            <tr key={record["pagePath"]}>
              <td>
                <a
                  href={"https://" + record["hostName"] + record["pagePath"]}
                  target="_blank"
                >
                  {record["pagePath"]}
                </a>
              </td>
              <td>{applyFormat(record["bounceRate"], "percentage")}</td>
              <td>{record["sessions"]}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="mb-3 has-text-grey is-size-7">
        Showing {offset.value + 1} to{" "}
        {offset.value + pageSize.value > recordCount.value
          ? recordCount.value
          : offset.value + pageSize.value}{" "}
        of {recordCount.value} entries
      </div>
      <button
        className="button is-info mr-3"
        disabled={offset.value === 0 ? true : false}
        onMouseDown={decreaseOffset}
      >
        <i className="icon fas fa-arrow-up"></i> <span>Previous</span>
      </button>
      <button
        className="button is-info"
        disabled={
          offset.value + pageSize.value > recordCount.value ? true : false
        }
        onMouseDown={increaseOffset}
      >
        <i className="icon fas fa-arrow-down"></i> <span>Next</span>
      </button>
    </>
  );
};

export default BounceRateToplistByPage;
