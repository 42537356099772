import { useSignal, useSignalEffect } from "@preact/signals-react";

import {
  userSignal,
  customerSignal,
  propertySignal,
  refreshCustomerSignal,
} from "../signals/userSignals";

import api from "../utils/api";
import Loading from "./Loading";

const GooglePropertyConfiguration = () => {
  const results = useSignal([]);

  const getResults = async () => {
    api(userSignal.value.access_token)
      .get("query/google_properties")
      .then((response) => {
        results.value = response.data;
      });
  };

  const getPropertyByName = (name) => {
    return customerSignal.value.google_properties.filter(
      (property) => property.name === name
    )[0];
  };

  const handleClick = (account, property) => {
    if (getPropertyByName(property.name)) {
      deleteProperty(getPropertyByName(property.name).id);
    } else {
      addProperty({
        name: property.name,
        display_name: property.displayName,
        account_name: account.name,
        account_display_name: account.displayName,
        customer_id: customerSignal.value.id,
      });
    }
  };

  const addProperty = (googlePropertyCreate) => {
    api(userSignal.value.access_token)
      .post("settings/google_property/add", googlePropertyCreate)
      .then((response) => {
        refreshCustomerSignal();
        propertySignal.value = response.data;
      });
  };

  const deleteProperty = (id) => {
    api(userSignal.value.access_token)
      .post("settings/google_property/delete/" + id)
      .then((response) => {
        refreshCustomerSignal();
      });
  };

  useSignalEffect(() => {
    getResults();
  });

  if (results.value.length === 0) {
    return <div>Loading...</div>;
  }

  return (
    <>
      {results.value["accounts"].map((account) => (
        <>
          <b>{account.displayName}</b>
          {account["properties"].map((property) => (
            <div className="p-1">
              <button
                className={
                  getPropertyByName(property.name)
                    ? "button is-info is-small"
                    : "button is-small"
                }
                onClick={() => handleClick(account, property)}
              >
                <span className="icon">
                  <i
                    className={
                      getPropertyByName(property.name)
                        ? "fas fa-check"
                        : "fas fa-plus"
                    }
                  ></i>
                </span>
              </button>
              <span className="ml-3">{property.displayName}</span>
            </div>
          ))}
        </>
      ))}
    </>
  );
};

export default GooglePropertyConfiguration;
