const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

const yearMonthToReadable = (yearMonth) => {
  const year = yearMonth.substring(0, 4);
  const month = yearMonth.substring(4, 6);

  return `${year}-${month}`;
};

export { capitalizeFirstLetter, yearMonthToReadable };
