import {
  userSignal,
  customerSignal,
  propertySignal,
  conversionGroupSignal,
  recordsSignal,
  dateSignal,
} from "../signals/userSignals";

import api from "../utils/api";

const GooglePropertySelector = () => {
  const handleSelect = (e) => {
    recordsSignal.value = null;
    dateSignal.value = null;
    getProperty(e.target.value);
  };

  const getProperty = (google_property_id) => {
    api(userSignal.value.access_token)
      .get(`settings/google_property/get/${google_property_id}`)
      .then((response) => {
        propertySignal.value = response.data;
        if (response.data.conversion_groups.length > 0) {
          conversionGroupSignal.value = response.data.conversion_groups[0];
        } else {
          conversionGroupSignal.value = null;
        }
      });
  };

  if (customerSignal.value === null) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <select
        id="google_property"
        onChange={handleSelect}
        value={propertySignal.value ? propertySignal.value["id"] : null}
      >
        {customerSignal.value.google_properties.map((googleProperty) => (
          <option key={googleProperty.id} value={googleProperty.id}>
            {googleProperty.display_name} ({googleProperty.account_display_name}
            )
          </option>
        ))}
      </select>
    </>
  );
};
export default GooglePropertySelector;
