import { useSignal } from "@preact/signals-react";

import DateSelector from "../components/DateSelector";
import ConversionGroupSelector from "../components/ConversionGroupSelector";
import GooglePropertySelector from "../components/GooglePropertySelector";
import GooglePropertyConfiguration from "../components/GooglePropertyConfiguration";
import ConversionGroupConfiguration from "../components/ConversionGroupConfiguration";
import ConversionConfiguration from "../components/ConversionConfiguration";

import { recordsSignal } from "../signals/userSignals";

const SelectorTile = ({ expanded }) => {
  const selectorEdit = useSignal("");

  const EditButton = ({ selectorName }) => {
    const handleClick = (selectorName) => {
      if (selectorEdit.value === selectorName) {
        selectorEdit.value = "";
      } else {
        selectorEdit.value = selectorName;
      }
    };

    return (
      <button
        className="button is-info"
        onClick={() => handleClick(selectorName)}
      >
        <span className="icon">
          <i className="fas fa-gear"></i>
        </span>
      </button>
    );
  };

  const CancelButton = () => {
    return (
      <button
        className="button is-danger is-outlined mt-3"
        onClick={() => (selectorEdit.value = "")}
      >
        <span className="icon">
          <i className="fas fa-times"></i>
        </span>
        <span>Cancel</span>
      </button>
    );
  };

  return (
    <>
      <div className="columns">
        <div className="column">
          <label htmlFor="google_property" className="label">
            Google Property
          </label>
          <div className="field has-addons">
            <div className="control select is-fullwidth">
              <GooglePropertySelector />
            </div>
            <div className="control">
              <EditButton selectorName="google_property" />
            </div>
          </div>
        </div>
        <div className="column">
          <label htmlFor="conversion_group" className="label">
            Key event
          </label>
          <div className="field has-addons">
            <div className="control select is-fullwidth">
              <ConversionGroupSelector />
            </div>
            <div className="control">
              <EditButton selectorName="conversion_group" />
            </div>
          </div>
        </div>
        <div className="column">
          {recordsSignal.value ? <DateSelector /> : <div> </div>}
        </div>
      </div>
      {selectorEdit.value !== "" && (
        <div>
          <hr />
          <div>
            {selectorEdit.value === "conversion_group" && (
              <>
                <ConversionGroupConfiguration />
                <ConversionConfiguration />
                <CancelButton />
              </>
            )}

            {selectorEdit.value === "google_property" && (
              <>
                <GooglePropertyConfiguration />
                <CancelButton />
              </>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default SelectorTile;
