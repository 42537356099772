import { conversionGroupSignal } from "../signals/userSignals";

import Heading from "../components/Heading";
import SessionsVsConversionsByChannelBarChart from "../visuals/SessionsVsConversionsByChannelBarChart";

const ChannelCompareTile = ({ records, expanded }) => {
  return (
    <div>
      <Heading
        title="Comparison by channel"
        subtitle={"Visits vs. " + conversionGroupSignal.value["name"]}
      />
      <SessionsVsConversionsByChannelBarChart records={records} />
    </div>
  );
};

export default ChannelCompareTile;
