import KpiCards from "../visuals/KpiCards";

const KpiTile = ({ records, expanded }) => {
  return (
    <>
      <KpiCards records={records} />
    </>
  );
};

export default KpiTile;
