import { Line } from "react-chartjs-2";
import colors from "../utils/color";
import {
  Chart,
  LineController,
  LineElement,
  PointElement,
  LinearScale,
  Title,
  CategoryScale,
  Filler,
} from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { reduceRecords, addTrend } from "../utils/data";
import { yearMonthToReadable } from "../utils/string";
import { conversionGroupSignal } from "../signals/userSignals";

Chart.register(
  LineController,
  LineElement,
  PointElement,
  LinearScale,
  Title,
  CategoryScale,
  Filler,
  ChartDataLabels
);

const SessionsVsConversionsByChannelLineChart = ({ records, channel }) => {
  const prepareData = (data) => {
    const filteredData = data.filter(
      (record) => record["firstUserDefaultChannelGroup"] === channel
    );

    const reduceConfig = {
      groupBy: ["yearMonth"],
      metrics: [
        { metric: "sessions", agg: "sum" },
        { metric: "cg-" + conversionGroupSignal.value["id"], agg: "sum" },
      ],
    };

    const trendConfig = reduceConfig;
    return addTrend(reduceRecords(filteredData, reduceConfig), trendConfig);
  };

  const reducedRecords = prepareData(records);

  const labels = reducedRecords.map((record) =>
    yearMonthToReadable(record["yearMonth"])
  );
  const datasets = [
    {
      label: "Visits",
      data: reducedRecords.map((record) => record["sessions"]),
      backgroundColor: colors.array[0].backgroundColor,
      borderColor: colors.array[0].borderColor,
      borderWidth: 3,
      yAxisID: "y-axis-1",
      pointRadius: 0,
    },
    {
      label: "Visits Trend",
      data: reducedRecords.map((record) => record["sessionsTrend"]),
      backgroundColor: colors.array[0].backgroundColor,
      borderColor: colors.array[0].borderColor,
      borderWidth: 2,
      borderDash: [5, 5],
      yAxisID: "y-axis-1",
      datalabels: {
        display: false,
      },
      pointRadius: 0,
    },
    {
      label: conversionGroupSignal.value["name"],
      data: reducedRecords.map(
        (record) => record["cg-" + conversionGroupSignal.value["id"]]
      ),
      backgroundColor: colors.array[1].backgroundColor,
      borderColor: colors.array[1].borderColor,
      borderWidth: 3,
      yAxisID: "y-axis-2",
      pointRadius: 0,
    },
    {
      label: conversionGroupSignal.value["name"] + " Trend",
      data: reducedRecords.map(
        (record) => record["cg-" + conversionGroupSignal.value["id"] + "Trend"]
      ),
      backgroundColor: colors.array[1].backgroundColor,
      borderColor: colors.array[1].borderColor,
      borderWidth: 2,
      borderDash: [5, 5],
      yAxisID: "y-axis-2",
      datalabels: {
        display: false,
      },
      pointRadius: 0,
    },
  ];

  const data = {
    labels: labels,
    datasets: datasets,
  };

  const options = {
    scales: {
      "y-axis-1": {
        // use the id directly
        type: "linear",
        display: true,
        position: "left",
        beginAtZero: true,
      },
      "y-axis-2": {
        // use the id directly
        type: "linear",
        display: true,
        position: "right",
        grid: {
          drawOnChartArea: false, // ensures the second y-axis doesn't draw grid lines
        },
        beginAtZero: true,
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      datalabels: {
        display: false,
      },
    },
  };

  return (
    <div className="chart-container">
      <Line data={data} plugins={[ChartDataLabels]} options={options} />
    </div>
  );
};

export default SessionsVsConversionsByChannelLineChart;
