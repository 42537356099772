import { useSignal, useSignalEffect } from "@preact/signals-react";

import { recordsSignal, dateSignal } from "../signals/userSignals";

import { yearMonthToReadable } from "../utils/string";

const DateSelector = () => {
  const startDate = useSignal(null);
  const endDate = useSignal(null);

  const findMinAndMaxDates = (records) => {
    if (records) {
      const dates = records.map((record) => record.yearMonth);
      const minDate = String(Math.min(...dates));
      const maxDate = String(Math.max(...dates));

      return { minDate: minDate, maxDate: maxDate };
    }
  };

  const generateYearMonthRange = (minYearMonth, maxYearMonth) => {
    const startDate = new Date(
      minYearMonth.substring(0, 4),
      parseInt(minYearMonth.substring(4, 6)) - 1
    );
    const endDate = new Date(
      maxYearMonth.substring(0, 4),
      parseInt(maxYearMonth.substring(4, 6)) - 1
    );

    let date = new Date(startDate);
    const yearMonthArray = [];

    while (date <= endDate) {
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Zorg dat de maand altijd twee cijfers heeft
      yearMonthArray.push(`${year}${month}`);
      date.setMonth(date.getMonth() + 1);
    }

    return yearMonthArray;
  };

  useSignalEffect(() => {
    if (recordsSignal.value) {
      const minDate = findMinAndMaxDates(recordsSignal.value).minDate;
      const maxDate = findMinAndMaxDates(recordsSignal.value).maxDate;

      if (startDate.value === null || minDate > startDate.value) {
        startDate.value = minDate;
      }

      if (endDate.value === null || maxDate < endDate.value) {
        endDate.value = maxDate;
      }
    } else {
      startDate.value = null;
      endDate.value = null;
    }
  });

  useSignalEffect(() => {
    if (
      startDate.value > endDate.value ||
      (startDate.value && endDate.value === null)
    ) {
      endDate.value = startDate.value;
    }
  });

  useSignalEffect(() => {
    if (
      endDate.value < startDate.value ||
      (endDate.value && startDate.value === null)
    ) {
      startDate.value = endDate.value;
    }
  });

  useSignalEffect(() => {
    if (startDate.value && endDate.value) {
      dateSignal.value = { startDate: startDate.value, endDate: endDate.value };
    }
  });

  if (dateSignal.value === null || recordsSignal.value === null) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <div className="columns">
        <div className="column">
          <label htmlFor="startDate" className="label">
            Start Date
          </label>
          <div className="select is-fullwidth">
            <select
              id="startDate"
              value={startDate.value}
              onChange={(e) => (startDate.value = e.target.value)}
            >
              {generateYearMonthRange(
                findMinAndMaxDates(recordsSignal.value).minDate,
                findMinAndMaxDates(recordsSignal.value).maxDate
              ).map((yearMonth) => (
                <option key={yearMonth} value={yearMonth}>
                  {yearMonth.substring(0, 4)}-{yearMonth.substring(4, 6)}
                </option>
              ))}
            </select>
          </div>
        </div>

        <div className="column">
          <label htmlFor="endDate" className="label">
            End Date
          </label>
          <div className="select is-fullwidth">
            <select
              id="endDate"
              value={endDate.value}
              onChange={(e) => (endDate.value = e.target.value)}
            >
              {generateYearMonthRange(
                findMinAndMaxDates(recordsSignal.value).minDate,
                findMinAndMaxDates(recordsSignal.value).maxDate
              ).map((yearMonth) => (
                <option key={yearMonth} value={yearMonth}>
                  {yearMonthToReadable(yearMonth)}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>
    </>
  );
};

export default DateSelector;
