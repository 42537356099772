import { reduceRecords } from "../utils/data";
import { conversionGroupSignal } from "../signals/userSignals";

const DifferenceByPaidGaugeChart = ({ records }) => {
  const prepareData = (data) => {
    const reduceConfig = {
      groupBy: ["paidVsOrganic"],
      metrics: [
        { metric: "sessions", agg: "sum" },
        { metric: "cg-" + conversionGroupSignal.value["id"], agg: "sum" },
      ],
    };

    const recategorizedData = data.map((record) => {
      if (record["firstUserDefaultChannelGroup"].includes("Paid")) {
        return { ...record, paidVsOrganic: "Paid" };
      } else {
        return { ...record, paidVsOrganic: "Organic" };
      }
    });

    return reduceRecords(recategorizedData, reduceConfig);
  };

  const reducedRecords = prepareData(records);
  const perentageDesktopSessions =
    reducedRecords[0]["sessions"] /
    (reducedRecords[0]["sessions"] + reducedRecords[1]["sessions"]);
  const percentageDesktopKeyEvents =
    reducedRecords[0]["cg-" + conversionGroupSignal.value["id"]] /
    (reducedRecords[0]["cg-" + conversionGroupSignal.value["id"]] +
      reducedRecords[1]["cg-" + conversionGroupSignal.value["id"]]);
  const difference = Math.round(
    Math.abs(perentageDesktopSessions - percentageDesktopKeyEvents) * 100
  );

  return (
    <>
      <div className="chart-container">
        <div className="title is-1 pt-5">{difference}%</div>
      </div>
    </>
  );
};

export default DifferenceByPaidGaugeChart;
