import { userSignal } from "../signals/userSignals";

function GoogleApiLogin() {
  return (
    <>
      <a
        className="button is-info"
        href={
          process.env.REACT_APP_API_BASE_URI +
          "auth/login?token=" +
          userSignal.value.access_token
        }
      >
        <span className="icon">
          <i className="fab fa-google"></i>
        </span>
        <span>Connect to Google Analytics</span>
      </a>
    </>
  );
}

export default GoogleApiLogin;
