const colors = {
  array: [
    {
      backgroundColor: "rgba(54, 162, 235, 0.2)",
      borderColor: "rgba(54, 162, 235, 1)",
    },
    {
      backgroundColor: "rgba(255, 99, 132, 0.2)",
      borderColor: "rgba(255, 99, 132, 1)",
    },
    {
      backgroundColor: "rgba(255, 206, 86, 0.2)",
      borderColor: "rgba(255, 206, 86, 1)",
    },
    {
      backgroundColor: "rgba(75, 192, 192, 0.2)",
      borderColor: "rgba(75, 192, 192, 1)",
    },
    {
      backgroundColor: "rgba(153, 102, 255, 0.2)",
      borderColor: "rgba(153, 102, 255, 1)",
    },
    {
      backgroundColor: "rgba(255, 159, 64, 0.2)",
      borderColor: "rgba(255, 159, 64, 1)",
    },
    {
      backgroundColor: "rgba(199, 199, 199, 0.2)",
      borderColor: "rgba(199, 199, 199, 1)",
    }, // Lichtgrijs
    {
      backgroundColor: "rgba(83, 102, 255, 0.2)",
      borderColor: "rgba(83, 102, 255, 1)",
    }, // Donkerblauw
    {
      backgroundColor: "rgba(40, 167, 69, 0.2)",
      borderColor: "rgba(40, 167, 69, 1)",
    }, // Groen
    {
      backgroundColor: "rgba(255, 193, 7, 0.2)",
      borderColor: "rgba(255, 193, 7, 1)",
    }, // Geel
    {
      backgroundColor: "rgba(220, 53, 69, 0.2)",
      borderColor: "rgba(220, 53, 69, 1)",
    }, // Rood
    {
      backgroundColor: "rgba(23, 162, 184, 0.2)",
      borderColor: "rgba(23, 162, 184, 1)",
    }, // Cyaan
    {
      backgroundColor: "rgba(111, 66, 193, 0.2)",
      borderColor: "rgba(111, 66, 193, 1)",
    }, // Paars
    {
      backgroundColor: "rgba(52, 58, 64, 0.2)",
      borderColor: "rgba(52, 58, 64, 1)",
    }, // Donkergrijs
    {
      backgroundColor: "rgba(253, 126, 20, 0.2)",
      borderColor: "rgba(253, 126, 20, 1)",
    }, // Oranje
    {
      backgroundColor: "rgba(108, 117, 125, 0.2)",
      borderColor: "rgba(108, 117, 125, 1)",
    }, // Middengrijs
    {
      backgroundColor: "rgba(0, 123, 255, 0.2)",
      borderColor: "rgba(0, 123, 255, 1)",
    }, // Helderblauw
    {
      backgroundColor: "rgba(102, 16, 242, 0.2)",
      borderColor: "rgba(102, 16, 242, 1)",
    }, // Indigo
    {
      backgroundColor: "rgba(201, 203, 207, 0.2)",
      borderColor: "rgba(201, 203, 207, 1)",
    }, // Zilver
    {
      backgroundColor: "rgba(244, 67, 54, 0.2)",
      borderColor: "rgba(244, 67, 54, 1)",
    }, // Felrood
  ],
};

colors["backgroundColors"] = colors["array"].map(
  (color) => color.backgroundColor
);
colors["borderColors"] = colors["array"].map((color) => color.borderColor);

colors["backgroundColors1"] = [
  "rgba(54, 162, 235, 0.4)",
  "rgba(54, 162, 235, 0.7)",
];
colors["backgroundColors2"] = [
  "rgba(255, 99, 132, 0.4)",
  "rgba(255, 99, 132, 0.7)",
];

const channels = [
  "Organic Search",
  "Direct",
  "Referral",
  "Email",
  "Organic Social",
  "Organic Video",
  "Paid Search",
  "Paid Social",
  "Paid Video",
  "Display",
  "Cross-network",
];
colors["channels"] = channels.map((channel, index) => ({
  [channel]: colors["array"][index],
}));

export default colors;
