import { googleLogout } from "@react-oauth/google";
import { clearSignals } from "../signals/userSignals";

const LogoutButton = () => {
  const logOut = () => {
    googleLogout();
    clearSignals();
  };

  return (
    <>
      <button className="button is-info" onClick={logOut}>
        <span className="icon">
          <i className="fas fa-sign-out-alt"></i>
        </span>
        <span>Log out</span>
      </button>
    </>
  );
};

export default LogoutButton;
