import { useSignalEffect, useSignal } from "@preact/signals-react";
import { propertySignal, userSignal } from "../signals/userSignals";
import api from "../utils/api";

import Heading from "../components/Heading";
import BounceRateToplistByPage from "../visuals/BounceRateToplistByPage";

const BounceRateTile = () => {
  const recordsPages = useSignal(null);

  const refreshRecordsPagesSignal = async (
    google_property_id = propertySignal.value.id
  ) => {
    api(userSignal.value.access_token)
      .get("/data_load/pages_data/" + google_property_id)
      .then((response) => {
        if (response.data === null || response.data.length === 0) {
          recordsPages.value = null;
        } else {
          recordsPages.value = response.data;
        }
      });
  };

  useSignalEffect(() => {
    if (propertySignal.value) {
      refreshRecordsPagesSignal(propertySignal.value.id);
    }
  });

  if (!recordsPages.value) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <Heading
        title="Bounce Rate"
        subtitle={
          "Top 10 pages, with more than 100 visits, with the worst bounce rate"
        }
      />
      <BounceRateToplistByPage records={recordsPages.value} />
    </div>
  );
};

export default BounceRateTile;
