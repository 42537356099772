import axios from "axios";

const api = (token = null) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: token ? `Bearer ${token}` : "",
  };

  return axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URI,
    headers,
  });
};

export default api;
