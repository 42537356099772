import { useSignal, useSignalEffect } from "@preact/signals-react";

import {
  userSignal,
  propertySignal,
  conversionGroupSignal,
  refreshPropertySignal,
  reloadRecordsSignal,
} from "../signals/userSignals";

import api from "../utils/api";

const ConversionConfiguration = () => {
  const filterQuery = useSignal("");
  const pagePaths = useSignal([]);
  const suggestedPagePaths = useSignal([]);
  const filteredPagePaths = useSignal([]);
  const basePagePaths = useSignal([]);
  const displayedPagePaths = useSignal([]);
  const selectedTab = useSignal("all");

  const getPagePaths = () => {
    api(userSignal.value.access_token)
      .get("query/page_paths/" + propertySignal.value.id)
      .then((response) => {
        if ("rows" in response.data) {
          pagePaths.value = response.data.rows;
        }
      });
  };

  const addConversion = (pagePath) => {
    const payload = {
      google_property_id: propertySignal.value.id,
      conversion_group_id: conversionGroupSignal.value.id,
      page_path: pagePath,
    };

    api(userSignal.value.access_token)
      .post("settings/conversion/add", payload)
      .then((response) => {
        refreshPropertySignal();
        reloadRecordsSignal();
      });
  };

  const deleteConversion = (conversion_id) => {
    api(userSignal.value.access_token)
      .post("settings/conversion/delete/" + conversion_id)
      .then((response) => {
        refreshPropertySignal();
        reloadRecordsSignal();
      });
  };

  useSignalEffect(() => {
    if (propertySignal.value) {
      getPagePaths();
    }
  });

  useSignalEffect(() => {
    filteredPagePaths.value = pagePaths.value
      .filter(
        (pagePath) =>
          filterQuery.value === "" ||
          pagePath.dimensionValues[0].value.includes(filterQuery.value)
      )
      .slice(0, 10);
  });

  useSignalEffect(() => {
    suggestedPagePaths.value = pagePaths.value
      .filter(
        (pagePath) =>
          pagePath.dimensionValues[0].value.includes("dank") ||
          pagePath.dimensionValues[0].value.includes("succes") ||
          pagePath.dimensionValues[0].value.includes("register")
      )
      .slice(0, 10);
  });

  useSignalEffect(() => {
    filteredPagePaths.value = pagePaths.value.slice(0, 10);
  });

  useSignalEffect(() => {
    if (selectedTab.value === "all") {
      basePagePaths.value = filteredPagePaths.value;
    } else {
      basePagePaths.value = suggestedPagePaths.value;
    }
  });

  useSignalEffect(() => {
    if (conversionGroupSignal.value) {
      displayedPagePaths.value = basePagePaths.value.filter(
        (pagePath) =>
          !conversionGroupSignal.value.conversions
            .map((conversion) => conversion.page_path)
            .includes(pagePath.dimensionValues[0].value)
      );
    }
  });

  if (conversionGroupSignal.value === null) {
    return <></>;
  }

  if (pagePaths.value.length === 0) {
    return <div>Loading...</div>;
  }

  return (
    <>
      {conversionGroupSignal.value &&
        conversionGroupSignal.value.conversions.map((conversion) => (
          <div className="my-2">
            <button
              className="button is-danger is-small mr-3"
              onClick={() => deleteConversion(conversion.id)}
            >
              <span className="icon">
                <i className="fas fa-minus"></i>
              </span>
            </button>
            {conversion.page_path}
          </div>
        ))}

      <div className="panel">
        {selectedTab.value === "all" && (
          <div className="panel-block">
            <p className="control has-icons-left">
              <input
                className="input"
                type="text"
                placeholder="Search"
                onChange={(e) => (filterQuery.value = e.target.value)}
              />
              <span className="icon is-left">
                <i className="fas fa-search" aria-hidden="true"></i>
              </span>
            </p>
          </div>
        )}

        <p className="panel-tabs">
          <a
            className={selectedTab.value == "all" && "is-active"}
            onClick={() => (selectedTab.value = "all")}
          >
            All pages
          </a>
          <a
            className={selectedTab.value == "suggested" && "is-active"}
            onClick={() => (selectedTab.value = "suggested")}
          >
            Suggested pages
          </a>
        </p>

        {displayedPagePaths.value.map((pagePath) => (
          <div className="panel-block" key={pagePath.dimensionValues[0].value}>
            <button
              className="button is-info is-small mr-3"
              onClick={() => addConversion(pagePath.dimensionValues[0].value)}
            >
              <span className="icon">
                <i className="fas fa-plus"></i>
              </span>
            </button>
            <a href={pagePath.dimensionValues[0].value} target="_blank">
              {pagePath.dimensionValues[0].value}
            </a>{" "}
            ({pagePath.metricValues[0].value} pageviews)
          </div>
        ))}
      </div>
    </>
  );
};

export default ConversionConfiguration;
