import colors from "../utils/color";

import { Doughnut } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  ArcElement,
  Tooltip,
  Legend,
} from "chart.js";
import { reduceRecords } from "../utils/data";
import { conversionGroupSignal } from "../signals/userSignals";

ChartJS.register(CategoryScale, LinearScale, ArcElement, Tooltip, Legend);

const ConversionsByPaidPieChart = ({ records }) => {
  const prepareData = (data) => {
    const reduceConfig = {
      groupBy: ["paidVsOrganic"],
      metrics: [
        { metric: "sessions", agg: "sum" },
        { metric: "cg-" + conversionGroupSignal.value["id"], agg: "sum" },
      ],
    };

    const recategorizedData = data.map((record) => {
      if (record["firstUserDefaultChannelGroup"].includes("Paid")) {
        return { ...record, paidVsOrganic: "Paid" };
      } else {
        return { ...record, paidVsOrganic: "Organic" };
      }
    });

    const reducedData = reduceRecords(recategorizedData, reduceConfig);

    return reducedData;
  };

  const reducedRecords = prepareData(records);

  const labels = reducedRecords.map((record) => record["paidVsOrganic"]);

  const data = {
    labels: labels,
    datasets: [
      {
        label: conversionGroupSignal.value["name"],
        data: reducedRecords.map(
          (record) => record["cg-" + conversionGroupSignal.value["id"]]
        ),
        backgroundColor: colors.backgroundColors2,
        borderColor: colors.borderColors[1],
        borderWidth: 1,
      },
    ],
  };

  const options = {
    plugins: {
      datalabels: {
        formatter: (value, ctx) => {
          let sum = 0;
          let dataArr = ctx.chart.data.datasets[0].data;
          dataArr.map((data) => {
            sum += data;
          });
          let percentage = ((value * 100) / sum).toFixed(0) + "%";
          return percentage;
        },
      },
      legend: { position: "bottom" },
    },
  };

  return (
    <>
      <div className="chart-container">
        <Doughnut data={data} options={options} />
      </div>
    </>
  );
};

export default ConversionsByPaidPieChart;
