import "../css/loading.css";

const Loading = () => {
  return (
    <div className="loading">
      <div className="uil-ring-css">
        <div></div>
      </div>
    </div>
  );
};
export default Loading;
