import { useSignal, useSignalEffect } from "@preact/signals-react";

import {
  userSignal,
  propertySignal,
  filteredRecordsSignal,
  conversionGroupSignal,
  refreshRecordsSignal,
} from "../signals/userSignals";

import { Navigate } from "react-router-dom";
import ExpandingSection from "../general/ExpandingSection";

import SelectorTile from "../tiles/SelectorTile";
import UserTile from "../tiles/HeadingTile";
import DeviceAndPaidTile from "../tiles/DeviceAndPaidTile";
import TrendTile from "../tiles/TrendTile";
import ChannelSplitTile from "../tiles/ChannelSplitTile";
import KpiTile from "../tiles/KpiTile";
import ChannelCompareTile from "../tiles/ChannelCompareTile";
import BounceRateTile from "../tiles/BounceRateTile";

const DashboardPage = () => {
  const expandedUserTile = useSignal(false);
  const expandedSelectorTile = useSignal(false);
  const expandedKpiTile = useSignal(false);
  const expandedDeviceAndPaidTile = useSignal(false);
  const expandedChart1Tile = useSignal(false);
  const expandedChart2Tile = useSignal(false);
  const expandedChart3Tile = useSignal(false);
  const expandedChart4Tile = useSignal(false);

  useSignalEffect(() => {
    if (
      propertySignal.value &&
      userSignal.value &&
      conversionGroupSignal.value &&
      conversionGroupSignal.value.conversions.length > 0
    ) {
      refreshRecordsSignal(propertySignal.value.id);
    }
  });

  if (userSignal.value === null) {
    return <Navigate to="/signin" />;
  }

  return (
    <>
      <div className="container py-5">
        <ExpandingSection expanded={expandedUserTile}>
          <UserTile expanded={expandedUserTile.value} />
        </ExpandingSection>

        <ExpandingSection expanded={expandedSelectorTile}>
          <SelectorTile expanded={expandedSelectorTile.value} />
        </ExpandingSection>

        {filteredRecordsSignal.value && conversionGroupSignal.value && (
          <>
            <ExpandingSection expanded={expandedKpiTile}>
              <KpiTile
                expanded={expandedKpiTile.value}
                records={filteredRecordsSignal.value}
              />
            </ExpandingSection>

            <ExpandingSection expanded={expandedDeviceAndPaidTile}>
              <DeviceAndPaidTile
                expanded={expandedDeviceAndPaidTile.value}
                records={filteredRecordsSignal.value}
              />
            </ExpandingSection>

            <ExpandingSection expanded={expandedChart1Tile}>
              <TrendTile
                records={filteredRecordsSignal.value}
                expanded={expandedChart1Tile.value}
              />
            </ExpandingSection>

            <ExpandingSection expanded={expandedChart2Tile}>
              <ChannelSplitTile
                records={filteredRecordsSignal.value}
                expanded={expandedChart2Tile.value}
              />
            </ExpandingSection>

            <ExpandingSection expanded={expandedChart3Tile}>
              <ChannelCompareTile
                records={filteredRecordsSignal.value}
                expanded={expandedChart3Tile.value}
              />
            </ExpandingSection>

            <ExpandingSection expanded={expandedChart4Tile}>
              <BounceRateTile />
            </ExpandingSection>
          </>
        )}
      </div>
    </>
  );
};

export default DashboardPage;
