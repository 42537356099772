import { signal } from "@preact/signals-react";
import api from "../utils/api";

const userSignal = signal(null);
const customerSignal = signal(null);
const propertySignal = signal(null);
const conversionGroupSignal = signal(null);
const recordsSignal = signal(null);
const filteredRecordsSignal = signal(null);
const dateSignal = signal(null);

const clearSignals = () => {
  userSignal.value = null;
  customerSignal.value = null;
  propertySignal.value = null;
  conversionGroupSignal.value = null;
  recordsSignal.value = null;
  filteredRecordsSignal.value = null;
  dateSignal.value = null;
};

const refreshCustomerSignal = () => {
  api(userSignal.value.access_token)
    .get("auth/me")
    .then((res) => {
      customerSignal.value = res.data;
      if (res.data.google_properties.length > 0) {
        refreshPropertySignal(res.data.google_properties[0].id);
      }
    })
    .catch((err) => console.log(err));
};

const refreshPropertySignal = (googlePropertyId = propertySignal.value.id) => {
  api(userSignal.value.access_token)
    .get("settings/google_property/get/" + googlePropertyId)
    .then((res) => {
      propertySignal.value = res.data;
    })
    .catch((err) => console.log(err));
};

const refreshRecordsSignal = async (
  google_property_id = propertySignal.value.id
) => {
  api(userSignal.value.access_token)
    .get("/data_load/main_data/" + google_property_id)
    .then((response) => {
      if (response.data === null || response.data.length === 0) {
        recordsSignal.value = null;
        reloadRecordsSignal(google_property_id);
      } else {
        recordsSignal.value = response.data;
      }
    });
};

const reloadRecordsSignal = async (
  google_property_id = propertySignal.value.id
) => {
  api(userSignal.value.access_token)
    .get("/data_extract/reload_queries/" + google_property_id)
    .then((response) => {
      refreshRecordsSignal(google_property_id);
    });
};

export {
  userSignal,
  customerSignal,
  propertySignal,
  conversionGroupSignal,
  recordsSignal,
  filteredRecordsSignal,
  dateSignal,
  clearSignals,
  refreshCustomerSignal,
  refreshPropertySignal,
  refreshRecordsSignal,
  reloadRecordsSignal,
};
