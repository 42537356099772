const Heading = ({ title = "Welcome", subtitle = "" }) => {
  return (
    <>
      <p className="title is-5">{title}&nbsp;</p>
      {subtitle ? <p className="subtitle is-6">{subtitle}</p> : ""}
    </>
  );
};

export default Heading;
