import { propertySignal, conversionGroupSignal } from "../signals/userSignals";

const ConversionGroupSelector = () => {
  const handleSelect = (e) => {
    conversionGroupSignal.value = propertySignal.value.conversion_groups.find(
      (conversion_group) => conversion_group.id === parseInt(e.target.value)
    );
  };

  if (
    propertySignal.value === null ||
    propertySignal.value.conversion_groups.length === 0
  ) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <select
        id="conversion_group"
        onChange={handleSelect}
        value={
          conversionGroupSignal.value ? conversionGroupSignal.value["id"] : null
        }
      >
        {propertySignal.value.conversion_groups.map((conversion_group) => (
          <option key={conversion_group.id} value={conversion_group.id}>
            {conversion_group.name}
          </option>
        ))}
      </select>
    </>
  );
};

export default ConversionGroupSelector;
