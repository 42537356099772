import { useSignal, useSignalEffect } from "@preact/signals-react";

import axios from "axios";
import api from "../utils/api";
import { useGoogleLogin } from "@react-oauth/google";
import { userSignal, customerSignal } from "../signals/userSignals";

function GoogleUserLogin() {
  const googleProfile = useSignal(null);

  const login = useGoogleLogin({
    onSuccess: (codeResponse) => (userSignal.value = codeResponse),
    onError: (error) => console.log("Login Failed:", error),
  });

  const getGoogleProfile = (access_token) => {
    axios
      .get(
        `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${access_token}`,
        {
          headers: {
            Authorization: `Bearer ${access_token}`,
            Accept: "application/json",
          },
        }
      )
      .then((res) => {
        googleProfile.value = res.data;
      })
      .catch((err) => console.log(err));
  };

  const upsertCustomer = (googleProfileJson) => {
    const payload = googleProfileJson;

    api()
      .post("settings/customer/upsert_from_google_profile", payload)
      .then((res) => {
        customerSignal.value = res.data;
      })
      .catch((err) => console.log(err));
  };

  useSignalEffect(() => {
    if (userSignal.value) {
      getGoogleProfile(userSignal.value.access_token);
    }
  });

  useSignalEffect(() => {
    if (googleProfile.value) {
      upsertCustomer(googleProfile.value);
    }
  });

  return (
    <>
      <button className="button is-info" onClick={login}>
        <span className="icon">
          <i className="fab fa-google"></i>
        </span>
        <span>Sign in with Google</span>
      </button>
    </>
  );
}

export default GoogleUserLogin;
